<template>
  <Connect @getContract="getContract"/>
  <div class="add">
    <van-nav-bar
        title="Recipient Details"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div class="main">
      <div class="top">
        <div>Please fill in your:</div>
        <div class="green">Product receiving details</div>
      </div>
      <div class="form">
        <div v-show="isFirst" class="step1">
          <div class="item">
            <div class="label">Receiver</div>
            <input v-model="form.u_name" class="first"/>
          </div>
          <div class="item popup">
            <div class="label">International Mobile Number</div>
            <div class="value">
              <div class="select" @click="openCode">
                <div class="text">{{ areaCode }}</div>
                <img src="@/assets/imgs/icon_arrowS.png" alt="">
              </div>
              <input v-model="phone"/>
            </div>
          </div>
          <div class="item popup">
            <van-popover v-model:show="showLogin" placement="right" theme="dark">
              <div class="tips">For log in to the product,<br/>your login credentials will<br/>be sent to the email address.</div>
              <template #reference>
                <div class="label">
                  <div>Login E-mail</div>
                  <img class="info" src="@/assets/imgs/icon_info.png" alt="">
                </div>
              </template>
            </van-popover>
            <input v-model="form.login_email"/>
          </div>
          <div class="item">
            <van-popover v-model:show="showContact" placement="right" theme="dark">
              <div class="tips">If you only have one email<br/>address, you can use it<br/>for all contact purposes.</div>
              <template #reference>
                <div class="label">
                  <div>Contact E-mail</div>
                  <img class="info" src="@/assets/imgs/icon_info.png" alt="">
                </div>
              </template>
            </van-popover>
            <input v-model="form.email"/>
          </div>
          <div class="item">
            <div class="label">Receiving Product Wallet Address</div>
            <input v-model="form.receiving_address"/>
          </div>
        </div>
        <div v-show="!isFirst" class="step2">
          <div class="item">
            <div class="label">Country</div>
            <div class="select" style="width: 100%;" @click="openCountry">
              <div class="text">{{ countryName }}</div>
              <img src="@/assets/imgs/icon_arrowS.png" alt="">
            </div>
          </div>
          <div class="item">
            <div class="label">Address Line 1</div>
            <input v-model="form.area_line_1"/>
          </div>
          <div class="item">
            <div class="label">Address Line 2</div>
            <input v-model="form.area_line_2"/>
          </div>
          <div class="item">
            <div class="label">City</div>
            <input v-model="form.city"/>
          </div>
          <div class="item">
            <div class="label">State/ Province/ Region</div>
            <input v-model="form.province"/>
          </div>
          <div class="item">
            <div class="label">Zip/ Postal Code</div>
            <input v-model="form.post_code"/>
          </div>
        </div>
      </div>
      <div v-show="isFirst" class="btnWrap">
        <div class="btn" @click="router.go(-1)">Back</div>
        <div class="btn" @click="handleNext">Next</div>
      </div>
      <div v-show="!isFirst" class="btnWrap next">
        <div class="btn" @click="handleSubmit">Save</div>
      </div>
    </div>
  </div>

  <!--选择国际区号-->
  <CountryCode ref="countryCode" @selected="handleConfirm"/>

  <!--选择国家弹框-->
  <Country ref="country" @selected="handleSelected"/>
</template>

<script setup>
  import { ref, reactive } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { addUserAddress, getUserAddress } from '@/request/api'
  import CountryCode from "@/components/CountryCode";
  import Country from '@/components/Country';
  import Connect from '@/components/Connect';
  import { showFailToast, showToast, showSuccessToast, showLoadingToast, closeToast } from "vant";

  const showLogin = ref(false)
  const showContact = ref(false)
  const isFirst = ref(true)
  const router = useRouter()
  const route = useRoute()

  const aId = route.query.edit
  const phone = ref('')
  const form = reactive({
    u_name: '',
    mobile: '',
    login_email: '',
    email: '',
    receiving_address: '',
    country_id: '',
    area_line_1: '',
    area_line_2: '',
    city: '',
    province: '',
    post_code: ''
  })

  const countryName = ref('')
  function handleSelected(val) {
    countryName.value = val.country_name
    form.country_id = val.country_id
  }

  let oldVal = 0
  getUserAddress({
    address: sessionStorage.getItem('address')
  }).then(res => {
    if(res.success) {
      oldVal = res.data.length
    }
  })

  let conContract = null, gasPrice = ''
  function getContract(obj) {
    conContract = obj.conContract
    gasPrice = obj.gasPrice
  }

  // Next
  function handleNext() {
    if(form.u_name == '') {
      showToast('Please Enter Receiver')
      return
    }
    if(areaCode.value == '') {
      showToast('Please select an international area code')
      return
    }
    if(phone.value == '') {
      showToast('Please Enter Mobile Number')
      return
    }
    if(form.login_email == '') {
      showToast('Please Enter Login E-mail')
      return
    }
    if(form.email == '') {
      showToast('Please Enter Contact E-mail')
      return
    }
    if(form.receiving_address == '') {
      showToast('Please Enter Receiving Product Wallet Address')
      return
    }
    isFirst.value = false
  }

  // 确认添加
  // let timer = null
  function handleSubmit() {
    if(form.country_id == '') {
      showToast('Please select a country')
      return
    }
    if(form.area_line_1 == '') {
      showToast('Please Enter Address Line 1')
      return
    }
    if(form.area_line_2 == '') {
      showToast('Please Enter Address Line 2')
      return
    }
    if(form.city == '') {
      showToast('Please Enter City')
      return
    }
    if(form.province == '') {
      showToast('Please Enter Province')
      return
    }
    if(form.post_code == '') {
      showToast('Please Enter Postal Code')
      return
    }
    form.address = sessionStorage.getItem('address')
    form.user_address_id = aId
    form.mobile = areaCode.value + ' ' + phone.value
    let zuDisable = false
    addUserAddress(form).then(async res => {
      if(res.success) {
        if(zuDisable) {
          return
        }
        zuDisable = true
        try {
          const res1 = await conContract.AddEditShippingAddress(res.data, {
            gasPrice,
            gasLimit: 200000
          })
          if(res1) {
            zuDisable = false
            setTimeout(() => {
              showSuccessToast('Operation Successful')
              router.go(-1)
            }, 1000)
            /*if(aId) {
              showSuccessToast('Operation Successful')
              router.go(-1)
              return
            }
            showLoadingToast({
              message: 'Loading...',
              forbidClick: true,
              duration: 0
            })
            timer = setInterval(async () => {
              const result = await getUserAddress({ address: sessionStorage.getItem('address') })
              if(result.success) {
                const newVal = result.data.length
                if(newVal > oldVal) {
                  clearInterval(timer)
                  closeToast()
                  showSuccessToast('Operation Successful')
                  router.go(-1)
                }
              }
            }, 1000)*/
          }
        } catch (e) {
          console.log(e)
          zuDisable = false
          showFailToast('Operation Failed')
        }
      } else {
        showToast(data.msg)
      }
    })
  }

  const countryCode = ref(null)
  function openCode() {
    countryCode.value.show = true
  }

  const areaCode = ref('')
  function handleConfirm(val) {
    areaCode.value = val.code
  }

  const country = ref(null)
  function openCountry() {
    country.value.show = true
  }

  /*onBeforeUnmount(() => {
    clearInterval(timer)
  })*/
</script>

<style lang="less" scoped>
.add{
  .main{
    padding: 10px 24px 30px 24px;

    .top{
      color: #F8F8F8;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 26px;

      .green{
        color: #06C8A1;
      }
    }
    .form{
      margin-bottom: 30px;

      .item{
        margin-bottom: 26px;

        .label{
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          position: relative;

          .info{
            width: 12px;
            margin-left: 8px;
          }
        }
        input{
          width: 100%;
          height: 48px;
          border: 1px solid #697377;
          border-radius: 10px;
          box-sizing: border-box;
          font-size: 16px;
          color: #697377;
          padding-left: 14px;
          margin-top: 8px;
        }
        .first{
          border: 1px solid #06C8A1;
          background: rgba(6, 200, 161, 0.10);
          color: #ffffff;
        }
        .value{
          display: flex;
          justify-content: space-between;
          margin-top: 8px;

          input{
            width: calc(100% - 105px);
            margin-top: 0;
          }
        }
        .select{
          display: flex;
          align-items: center;
          width: 95px;
          height: 48px;
          border: 1px solid #697377;
          border-radius: 10px;
          color: #697377;
          background: #ffffff;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 10px 0 14px;

          .text{
            font-size: 16px;
            color: #697377;
          }
          img{
            width: 10px;
            height: 10px;
          }
        }
        >.select{
          margin-top: 8px;
        }
      }
      .item:last-child{
        margin-bottom: 0;
      }
      .popup{
        margin-bottom: 10px;
      }
    }
    .btnWrap{
      display: flex;
      justify-content: space-between;
      padding: 0 46px;

      .btn{
        width: 92px;
        height: 36px;
        box-sizing: border-box;
        background: #697377;
        border-radius: 15px;
        border: 1px solid #ffffff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn:hover{
        background: #06C8A1;
        border-color: #06C8A1;
      }
    }
    .next{
      justify-content: center;
    }
  }
}
</style>
